.markdown-prose {
  /*
    JSX related
  */

  & div[data-jsx-element],
  & .jsx-element {
    margin-bottom: var(--elements-mb);
  }

  & .no-margin {
    & div[data-jsx-element],
    & .jsx-element {
      margin-bottom: 0;
    }
  }

  & .not-margin-bottom {
    & > *:last-child {
      margin-bottom: 0 !important;
    }

    &.self {
      margin-bottom: 0 !important;
    }
  }

  & .not-last-margin-bottom *:last-child {
    margin-bottom: 0 !important;
  }

  &.not-last-margin-bottom *:last-child {
    margin-bottom: 0 !important;
  }

  /*
    Pre / Code
  */
  & pre::-webkit-scrollbar-thumb {
    background: var(--color-gray10);
    -webkit-border-radius: 1ex;
    -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
  }

  & pre::-webkit-scrollbar {
    height: 2px;
    width: 8px;
    background: var(--color-gray1);
  }

  & pre::-webkit-scrollbar-corner {
    background: #000;
  }

  & pre::-webkit-scrollbar {
    height: 8px;
    width: 8px;
    background: var(--color-gray6);
  }

  & code:not(& pre code):not(.not-prose code):not(shiki code) {
    background: linear-gradient(
      to bottom right,
      theme('colors.accent.DEFAULT/0.5'),
      theme('colors.accent.DEFAULT/0.2')
    );
    font-family: theme('fontFamily.mono');
    font-weight: theme('fontWeight.normal');
    padding: theme('spacing[1.5]') theme('spacing[0.5]');
    margin: calc(-1 * theme('spacing[1.5]')) calc(-1 * theme('spacing[0.5]'));
    border-radius: theme('borderRadius.md');
    border: 1px solid theme('colors.border/0.5');
    color: theme('colors.foreground');
    &::after,
    &::before {
      content: '';
    }
  }

  /*
    LI / P
  */
  & p:not(.not-prose p),
  & li:not(.not-prose li) {
    margin: 0;
    margin-bottom: 1.5em;
  }

  & li:not(.not-prose li) {
    margin: 0 !important;
    margin-bottom: 0.5em !important;
  }

  /*
    Image
  */
  & > p > img {
    margin: auto;
  }

  /*
    Title
  */
  & > h1,
  h2,
  h3 {
    font-family: var(--font-grotesk);
  }

  /*
    Blockquote
  */
  & > blockquote {
    background-color: hsl(var(--primary) / 0.1);
    border-color: hsl(var(--primary) / 0.5);

    & p {
      padding: 1rem;
    }

    & code {
      font-style: normal;
    }
  }
}

.mdx-prose {
  width: 100% !important;

  --elements-mb: 1.5em !important;
  --space: 0px;

  padding-bottom: 120px !important;

  padding-left: 24px;
  padding-right: 24px;
  max-width: unset !important;

  @media (min-width: 1024px) {
    max-width: min(100%, 80ch) !important;

    --space: 60px;
    --elements-mb: 2em !important;

    padding-left: var(--space);
    padding-right: var(--space);
  }

  @media (min-width: 1500px) {
    max-width: 90ch !important;

    --space: 150px;
  }

  @media (min-width: 1800px) {
    max-width: 100ch !important;

    --space: 200px;
  }

  & .super-wrapper:not(.not-prose .super-wrapper):not(.not-margin .super-wrapper) {
    margin-left: calc(var(--space) * -0.5) !important;
    margin-right: calc(var(--space) * -0.5) !important;
    width: calc(100% + var(--space)) !important;
  }

  & .flex-code > pre {
    font-size: 12px;
    overflow: hidden;
    width: 100%;
  }

  & .sp-super-wrapper,
  & .mux-video,
  & > iframe {
    margin-bottom: var(--elements-mb) !important;
  }

  & div[data-jsx-element],
  & .jsx-element {
    margin-bottom: var(--elements-mb);
  }

  & .no-margin {
    & div[data-jsx-element],
    & .jsx-element {
      margin-bottom: 0;
    }
  }

  & .not-margin-bottom *:last-child {
    margin-bottom: 0 !important;
  }
}

.mdxeditor * {
  --space: 0px !important;
}

.no-margin-top-first-child {
  & > *:first-child {
    margin-top: 0 !important;
  }
}

.force-prose-style {
  &:not(.force-prose-style-inline) {
    width: 100%;
    display: flex;
  }
  & > div > *:first-child {
    margin-top: 0 !important;
  }

  & > div > *:last-child {
    margin-bottom: 0 !important;
  }

  & * {
    color: hsl(var(--foreground)) !important;
  }
}

.prose {
  scroll-margin-top: 40px;

  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    position: relative;
    scroll-margin-top: 70px;

    &:hover {
      & .icon-link {
        opacity: 1;
      }
    }
  }

  & .icon.icon-link {
    width: 20px;
    height: 100%;
    display: block;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: -24px;

    opacity: 0;
    transition: opacity 0.2s ease;
    &::before {
      content: '#';
      font-size: 1.5rem;
      color: hsl(var(--primary));
    }
  }

  & > pre {
    padding: 0 !important;
  }
}

.markdown {
  ul {
    list-style: disc !important;
    padding-left: 1.1rem;
  }

  ol {
    list-style: decimal !important;
    padding-left: 1.1rem;
  }

  a {
    color: hsl(var(--primary)) !important;

    &:hover {
      text-decoration: underline !important;
    }
  }

  .code-highlight {
    background-color: hsl(var(--card));
    max-width: 100%;
    overflow: auto;
    font-size: 14px;
    padding: 2px;
    border-radius: 4px;
  }
}

.mdxeditor > div:nth-child(2):not([role='dialog']) {
  min-height: 100%;
}

.mdxeditor {
  & div[data-editor-block-type] {
    width: 100%;

    & > div {
      width: 100%;
    }
  }
}

.prose-last-element-no-margin {
  & > *:last-child {
    margin-bottom: 0 !important;
  }

  & > div > *:last-child {
    margin-bottom: 0 !important;
  }

  &.self {
    margin-bottom: 0 !important;
  }
}

@keyframes blink {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.mdxeditor-root-contenteditable:first-child:focus-within {
  .markdown-prose[class*='_placeholder_'] {
    cursor: text;

    & > p {
      position: relative;

      &::before {
        content: '';
        border-left: 2px solid white;
        position: absolute;
        height: 24px;
        top: 4px;
        animation: blink 1s steps(1, end) infinite;
      }
    }
  }
}

/* Shiky snippet
   https://shiki.style/guide/dual-themes#query-based-dark-mode */
.dark {
  .shiki {
    background-color: var(--shiki-dark-bg) !important;
  }

  .shiki,
  .shiki span {
    color: var(--shiki-dark) !important;

    /* Optional, if you also want font styles */
    font-style: var(--shiki-dark-font-style) !important;
    font-weight: var(--shiki-dark-font-weight) !important;
    text-decoration: var(--shiki-dark-text-decoration) !important;

    &.highlighted-word {
      background-color: hsl(var(--accent) / 0.4) !important;
      border: 1px solid hsl(var(--accent) / 0.8) !important;
    }
  }
}

.shiki {
  padding: 1rem 1.5rem;

  & .line.highlighted {
    background: hsl(var(--primary) / 0.2) !important;
    transition: background-color 0.5s;
    margin: 0 -1.5rem;
    padding: 0 1.5rem;
    width: calc(100% + 48px);
    display: inline-block;

    &.error {
      background: hsl(var(--destructive) / 0.2) !important;
    }

    &.warning {
      background: hsl(var(--warning) / 0.2) !important;
    }
  }

  & .highlighted-word {
    background-color: hsl(var(--accent) / 0.6) !important;
    border: 1px solid hsl(var(--accent) / 0.8) !important;
    padding: 1px 3px;
    margin: -1px -3px;
    border-radius: 4px;
    display: inline-block;
  }

  & .diff {
    transition: background-color 0.5s;
    margin: 0 -24px;
    padding: 0 24px;
    width: calc(100% + 48px);
    display: inline-block;

    &::before {
      position: absolute;
      left: 10px;
    }

    &.remove {
      background-color: hsl(var(--destructive) / 0.2) !important;
      opacity: 0.7;

      &::before {
        content: '-';
        color: hsl(var(--destructive));
      }
    }

    &.add {
      background-color: hsl(var(--success) / 0.2) !important;

      &::before {
        content: '+';
        color: hsl(var(--success));
      }
    }
  }

  &.has-focused {
    & .line:not(.focused) {
      filter: blur(0.08rem);
      opacity: 0.6;
      transition:
        filter 0.35s,
        opacity 0.35s;
    }

    &:hover .line:not(.focused) {
      filter: none;
      opacity: 0.9;
    }
  }
}

.mdx-children {
  & *:not(:last-child) {
    margin-bottom: 0px !important;
  }
}

.mdx-editor-theme {
  --accentBase: hsl(var(--primary)) !important;
  --accentBgSubtle: hsl(var(--primary) / 0.8) !important;
  --accentBg: hsl(var(--primary)) !important;
  --accentBgHover: hsl(var(--primary) / 0.9) !important;
  --accentBgActive: hsl(var(--primary) / 0.7) !important;
  --accentLine: hsl(var(--primary)) !important;
  --accentBorder: hsl(var(--primary) / 0.8) !important;
  --accentBorderHover: hsl(var(--primary) / 0.9) !important;
  --accentSolid: hsl(var(--primary)) !important;
  --accentSolidHover: hsl(var(--primary) / 0.9) !important;
  --accentText: hsl(var(--accent-foreground)) !important;
  --accentTextContrast: hsl(var(--muted-foreground) / 0.9) !important;
  --baseBase: hsl(var(--background)) !important;
  --baseBgSubtle: hsl(var(--card) / 1) !important;
  --baseBg: hsl(var(--background)) !important;
  --baseBgHover: hsl(var(--background) / 0.9) !important;
  --baseBgActive: hsl(var(--accent) / 0.7) !important;
  --baseLine: hsl(var(--border)) !important;
  --baseBorder: hsl(var(--border) / 0.8) !important;
  --baseBorderHover: hsl(var(--border) / 0.9) !important;
  --baseSolid: hsl(var(--background)) !important;
  --baseSolidHover: hsl(var(--foreground) / 0.8) !important;
  --baseText: hsl(var(--foreground)) !important;
  --baseTextContrast: hsl(var(--foreground) / 0.9) !important;

  --basePageBg: hsl(var(--card)) !important;

  color: var(--baseText) !important;
  background: var(--baseBg) !important;

  --font-code: 'var(--font-mono)', monospace !important;
  --font-body: 'var(--font-sans)', sans-serif !important;
}

.mdxeditor-popup-container {
  --baseBg: hsl(var(--accent)) !important;
}

.small-mdx-editor {
  width: 100% !important;
  max-width: unset !important;
}

.mdxeditor-content-editable {
  padding: 8px 32px !important;
}

.all-break-word {
  & * {
    word-break: break-word;
  }
}
