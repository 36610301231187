.dark {
  --color-gray1: hsl(240, 33.1%, 5%);
  --color-gray1-hsl: 240 33.1% 5%;
  --color-gray2: hsl(240, 17.1%, 9%);
  --color-gray2-hsl: 240 17.1% 9%;
  --color-gray3: hsl(220, 16%, 12.2%);
  --color-gray3-hsl: 220 16% 12.2%;
  --color-gray4: hsl(210, 14%, 14.2%);
  --color-gray4-hsl: 210 14% 14.2%;
  --color-gray5: hsl(240, 12.1%, 16.2%);
  --color-gray5-hsl: 240 12.1% 16.2%;
  --color-gray6: hsl(216, 10%, 20.2%);
  --color-gray6-hsl: 201deg 6.2% 17.5%;
  --color-gray7: hsl(203, 6%, 21.3%);
  --color-gray7-hsl: 203deg 6% 21.3%;
  --color-gray8: hsl(207, 5.6%, 28.6%);
  --color-gray8-hsl: 207deg 5.6% 28.6%;
  --color-gray9: hsl(206, 6%, 30.9%);
  --color-gray9-hsl: 206deg 6% 30.9%;
  --color-gray10: hsl(206, 5.2%, 49.5%);
  --color-gray10-hsl: 206deg 5.2% 49.5%;
  --color-gray11: hsl(215, 6%, 61.1%);
  --color-gray11-hsl: 215 6% 61.1%;
  --color-gray12: hsl(210, 6%, 98%);
  --color-gray12-hsl: 210deg 6% 98%;
  --color-sky1: hsl(205, 45%, 8.6%);
  --color-sky1-hsl: 205deg 45% 8.6%;
  --color-sky2: hsl(202, 71.4%, 9.6%);
  --color-sky2-hsl: 202deg 71.4% 9.6%;
  --color-sky3: hsl(201, 74.6%, 12.2%);
  --color-sky3-hsl: 201deg 74.6% 12.2%;
  --color-sky4: hsl(201, 77.4%, 14.4%);
  --color-sky4-hsl: 201deg 77.4% 14.4%;
  --color-sky5: hsl(200, 80.3%, 16.5%);
  --color-sky5-hsl: 200deg 80.3% 16.5%;
  --color-sky6: hsl(200, 84.1%, 18.9%);
  --color-sky6-hsl: 200deg 84.1% 18.9%;
  --color-sky7: hsl(199, 90.2%, 22.1%);
  --color-sky7-hsl: 199deg 90.2% 22.1%;
  --color-sky8: hsl(198, 100%, 26.1%);
  --color-sky8-hsl: 198deg 100% 26.1%;
  --color-sky9: hsl(193, 98%, 70%);
  --color-sky9-hsl: 193deg 98% 70%;
  --color-sky10: hsl(192, 100%, 77%);
  --color-sky10-hsl: 192deg 100% 77%;
  --color-sky11: hsl(192, 85%, 55.8%);
  --color-sky11-hsl: 192deg 85% 55.8%;
  --color-sky12: hsl(198, 98%, 95.8%);
  --color-sky12-hsl: 198deg 98% 95.8%;
  --color-red1: hsl(353, 23%, 9.8%);
  --color-red1-hsl: 353deg 23% 9.8%;
  --color-red2: hsl(357, 34.4%, 12%);
  --color-red2-hsl: 357deg 34.4% 12%;
  --color-red3: hsl(356, 43.4%, 16.4%);
  --color-red3-hsl: 356deg 43.4% 16.4%;
  --color-red4: hsl(356, 47.6%, 19.2%);
  --color-red4-hsl: 356deg 47.6% 19.2%;
  --color-red5: hsl(356, 51.1%, 21.9%);
  --color-red5-hsl: 356deg 51.1% 21.9%;
  --color-red6: hsl(356, 55.2%, 25.9%);
  --color-red6-hsl: 356deg 55.2% 25.9%;
  --color-red7: hsl(357, 60.2%, 31.8%);
  --color-red7-hsl: 357deg 60.2% 31.8%;
  --color-red8: hsl(358, 65%, 40.4%);
  --color-red8-hsl: 358deg 65% 40.4%;
  --color-red9: hsl(358, 75%, 59%);
  --color-red9-hsl: 358deg 75% 59%;
  --color-red10: hsl(358, 85.3%, 64%);
  --color-red10-hsl: 358deg 85.3% 64%;
  --color-red11: hsl(358, 100%, 69.5%);
  --color-red11-hsl: 358deg 100% 69.5%;
  --color-red12: hsl(351, 89%, 96%);
  --color-red12-hsl: 351deg 89% 96%;
  --color-green1: hsl(146, 30%, 7.4%);
  --color-green1-hsl: 146deg 30% 7.4%;
  --color-green2: hsl(155, 44.2%, 8.4%);
  --color-green2-hsl: 155deg 44.2% 8.4%;
  --color-green3: hsl(155, 46.7%, 10.9%);
  --color-green3-hsl: 155deg 46.7% 10.9%;
  --color-green4: hsl(154, 48.4%, 12.9%);
  --color-green4-hsl: 154deg 48.4% 12.9%;
  --color-green5: hsl(154, 49.7%, 14.9%);
  --color-green5-hsl: 154deg 49.7% 14.9%;
  --color-green6: hsl(154, 50.9%, 17.6%);
  --color-green6-hsl: 154deg 50.9% 17.6%;
  --color-green7: hsl(153, 51.8%, 21.8%);
  --color-green7-hsl: 153deg 51.8% 21.8%;
  --color-green8: hsl(151, 51.7%, 28.4%);
  --color-green8-hsl: 151deg 51.7% 28.4%;
  --color-green9: hsl(151, 55%, 41.5%);
  --color-green9-hsl: 151deg 55% 41.5%;
  --color-green10: hsl(151, 49.3%, 46.5%);
  --color-green10-hsl: 151deg 49.3% 46.5%;
  --color-green11: hsl(151, 50%, 53.2%);
  --color-green11-hsl: 151deg 50% 53.2%;
  --color-green12: hsl(137, 72%, 94%);
  --color-green12-hsl: 137deg 72% 94%;
  --color-blue1: hsl(212, 35%, 9.2%);
  --color-blue1-hsl: 212deg 35% 9.2%;
  --color-blue2: hsl(216, 50%, 11.8%);
  --color-blue2-hsl: 216deg 50% 11.8%;
  --color-blue3: hsl(214, 59.4%, 15.3%);
  --color-blue3-hsl: 214deg 59.4% 15.3%;
  --color-blue4: hsl(214, 65.8%, 17.9%);
  --color-blue4-hsl: 214deg 65.8% 17.9%;
  --color-blue5: hsl(213, 71.2%, 20.2%);
  --color-blue5-hsl: 213deg 71.2% 20.2%;
  --color-blue6: hsl(212, 77.4%, 23.1%);
  --color-blue6-hsl: 212deg 77.4% 23.1%;
  --color-blue7: hsl(211, 85.1%, 27.4%);
  --color-blue7-hsl: 211deg 85.1% 27.4%;
  --color-blue8: hsl(211, 89.7%, 34.1%);
  --color-blue8-hsl: 211deg 89.7% 34.1%;
  --color-blue9: hsl(206, 100%, 50%);
  --color-blue9-hsl: 206deg 100% 50%;
  --color-blue10: hsl(209, 100%, 60.6%);
  --color-blue10-hsl: 209deg 100% 60.6%;
  --color-blue11: hsl(210, 100%, 66.1%);
  --color-blue11-hsl: 210deg 100% 66.1%;
  --color-blue12: hsl(206, 98%, 95.8%);
  --color-blue12-hsl: 206deg 98% 95.8%;
  --color-pink1: hsl(318, 25%, 9.6%);
  --color-pink1-hsl: 318deg 25% 9.6%;
  --color-pink2: hsl(319, 32.2%, 11.6%);
  --color-pink2-hsl: 319deg 32.2% 11.6%;
  --color-pink3: hsl(319, 41%, 16%);
  --color-pink3-hsl: 319deg 41% 16%;
  --color-pink4: hsl(320, 45.4%, 18.7%);
  --color-pink4-hsl: 320deg 45.4% 18.7%;
  --color-pink5: hsl(320, 49%, 21.1%);
  --color-pink5-hsl: 320deg 49% 21.1%;
  --color-pink6: hsl(321, 53.6%, 24.4%);
  --color-pink6-hsl: 321deg 53.6% 24.4%;
  --color-pink7: hsl(321, 61.1%, 29.7%);
  --color-pink7-hsl: 321deg 61.1% 29.7%;
  --color-pink8: hsl(322, 74.9%, 37.5%);
  --color-pink8-hsl: 322deg 74.9% 37.5%;
  --color-pink9: hsl(322, 65%, 54.5%);
  --color-pink9-hsl: 322deg 65% 54.5%;
  --color-pink10: hsl(323, 72.8%, 59.2%);
  --color-pink10-hsl: 323deg 72.8% 59.2%;
  --color-pink11: hsl(325, 90%, 66.4%);
  --color-pink11-hsl: 325deg 90% 66.4%;
  --color-pink12: hsl(322, 90%, 95.8%);
  --color-pink12-hsl: 322deg 90% 95.8%;
  --color-orange1: hsl(30, 70%, 7.2%);
  --color-orange1-hsl: 30deg 70% 7.2%;
  --color-orange2: hsl(28, 100%, 8.4%);
  --color-orange2-hsl: 28deg 100% 8.4%;
  --color-orange3: hsl(26, 91.1%, 11.6%);
  --color-orange3-hsl: 26deg 91.1% 11.6%;
  --color-orange4: hsl(25, 88.3%, 14.1%);
  --color-orange4-hsl: 25deg 88.3% 14.1%;
  --color-orange5: hsl(24, 87.6%, 16.6%);
  --color-orange5-hsl: 24deg 87.6% 16.6%;
  --color-orange6: hsl(24, 88.6%, 19.8%);
  --color-orange6-hsl: 24deg 88.6% 19.8%;
  --color-orange7: hsl(24, 92.4%, 24%);
  --color-orange7-hsl: 24deg 92.4% 24%;
  --color-orange8: hsl(25, 100%, 29%);
  --color-orange8-hsl: 25deg 100% 29%;
  --color-orange9: hsl(24, 94%, 50%);
  --color-orange9-hsl: 24deg 94% 50%;
  --color-orange10: hsl(24, 100%, 58.5%);
  --color-orange10-hsl: 24deg 100% 58.5%;
  --color-orange11: hsl(24, 100%, 62.2%);
  --color-orange11-hsl: 24deg 100% 62.2%;
  --color-orange12: hsl(24, 97%, 93.2%);
  --color-orange12-hsl: 24deg 97% 93.2%;
  --color-yellow1: hsl(45, 100%, 5.5%);
  --color-yellow1-hsl: 45deg 100% 5.5%;
  --color-yellow2: hsl(46, 100%, 6.7%);
  --color-yellow2-hsl: 46deg 100% 6.7%;
  --color-yellow3: hsl(45, 100%, 8.7%);
  --color-yellow3-hsl: 45deg 100% 8.7%;
  --color-yellow4: hsl(45, 100%, 10.4%);
  --color-yellow4-hsl: 45deg 100% 10.4%;
  --color-yellow5: hsl(47, 100%, 12.1%);
  --color-yellow5-hsl: 47deg 100% 12.1%;
  --color-yellow6: hsl(49, 100%, 14.3%);
  --color-yellow6-hsl: 49deg 100% 14.3%;
  --color-yellow7: hsl(49, 90.3%, 18.4%);
  --color-yellow7-hsl: 49deg 90.3% 18.4%;
  --color-yellow8: hsl(50, 100%, 22%);
  --color-yellow8-hsl: 50deg 100% 22%;
  --color-yellow9: hsl(53, 92%, 50%);
  --color-yellow9-hsl: 53deg 92% 50%;
  --color-yellow10: hsl(54, 100%, 68%);
  --color-yellow10-hsl: 54deg 100% 68%;
  --color-yellow11: hsl(48, 100%, 47%);
  --color-yellow11-hsl: 48deg 100% 47%;
  --color-yellow12: hsl(53, 100%, 91%);
  --color-yellow12-hsl: 53deg 100% 91%;
  --color-contrast: hsl(0, 0%, 100%);
  --color-contrast-hsl: 0deg 0% 100%;
  --color-text-primary: hsl(0, 0%, 100%);
  --color-text-primary-hsl: 0deg 0% 100%;
  --color-text-secondary: hsl(206, 6%, 63%);
  --color-text-secondary-hsl: 206deg 6% 63%;
}
.light {
  --color-gray1: hsl(0 0% 98%);
  --color-gray1-hsl: 0 0% 98%;
  --color-gray2: hsl(210, 40%, 98%);
  --color-gray2-hsl: 210deg 40% 98%;
  --color-gray3: hsl(214, 34%, 93%);
  --color-gray3-hsl: 214deg 34% 93%;
  --color-gray4: hsl(213, 32%, 90%);
  --color-gray4-hsl: 213deg 32% 90%;
  --color-gray5: hsl(213, 30%, 88%);
  --color-gray5-hsl: 213deg 30% 88%;
  --color-gray6: hsl(214, 24%, 82%);
  --color-gray6-hsl: 214deg 24% 82%;
  --color-gray7: hsl(207, 15%, 75%);
  --color-gray7-hsl: 207deg 15% 75%;
  --color-gray8: hsl(205, 10%, 70%);
  --color-gray8-hsl: 205deg 10% 70%;
  --color-gray9: hsl(206, 6%, 56%);
  --color-gray9-hsl: 206deg 6% 56%;
  --color-gray10: hsl(206, 5%, 52%);
  --color-gray10-hsl: 206deg 5% 52%;
  --color-gray11: hsl(206, 6%, 43%);
  --color-gray11-hsl: 206deg 6% 43%;
  --color-gray12: hsl(206, 24%, 9%);
  --color-gray12-hsl: 206deg 24% 9%;
  --color-sky1: hsl(193, 100%, 98.8%);
  --color-sky1-hsl: 193deg 100% 98.8%;
  --color-sky2: hsl(193, 100%, 97.3%);
  --color-sky2-hsl: 193deg 100% 97.3%;
  --color-sky3: hsl(193, 99%, 94.7%);
  --color-sky3-hsl: 193deg 99% 94.7%;
  --color-sky4: hsl(193, 91.4%, 91.4%);
  --color-sky4-hsl: 193deg 91.4% 91.4%;
  --color-sky5: hsl(194, 82%, 86.6%);
  --color-sky5-hsl: 194deg 82% 86.6%;
  --color-sky6: hsl(194, 74.1%, 79.5%);
  --color-sky6-hsl: 194deg 74.1% 79.5%;
  --color-sky7: hsl(194, 72.3%, 69.6%);
  --color-sky7-hsl: 194deg 72.3% 69.6%;
  --color-sky8: hsl(193, 77.9%, 53.9%);
  --color-sky8-hsl: 193deg 77.9% 53.9%;
  --color-sky9: hsl(193, 98%, 70%);
  --color-sky9-hsl: 193deg 98% 70%;
  --color-sky10: hsl(193, 87%, 66.5%);
  --color-sky10-hsl: 193deg 87% 66.5%;
  --color-sky11: hsl(195, 100%, 31.5%);
  --color-sky11-hsl: 195deg 100% 31.5%;
  --color-sky12: hsl(195, 100%, 13%);
  --color-sky12-hsl: 195deg 100% 13%;
  --color-red1: hsl(359, 100%, 99.4%);
  --color-red1-hsl: 359deg 100% 99.4%;
  --color-red2: hsl(359, 100%, 98.6%);
  --color-red2-hsl: 359deg 100% 98.6%;
  --color-red3: hsl(360, 100%, 96.8%);
  --color-red3-hsl: 360deg 100% 96.8%;
  --color-red4: hsl(360, 97.9%, 94.8%);
  --color-red4-hsl: 360deg 97.9% 94.8%;
  --color-red5: hsl(360, 90.2%, 91.9%);
  --color-red5-hsl: 360deg 90.2% 91.9%;
  --color-red6: hsl(360, 81.7%, 87.8%);
  --color-red6-hsl: 360deg 81.7% 87.8%;
  --color-red7: hsl(359, 74.2%, 81.7%);
  --color-red7-hsl: 359deg 74.2% 81.7%;
  --color-red8: hsl(359, 69.5%, 74.3%);
  --color-red8-hsl: 359deg 69.5% 74.3%;
  --color-red9: hsl(358, 75%, 59%);
  --color-red9-hsl: 358deg 75% 59%;
  --color-red10: hsl(358, 69.4%, 55.2%);
  --color-red10-hsl: 358deg 69.4% 55.2%;
  --color-red11: hsl(358, 65%, 48.7%);
  --color-red11-hsl: 358deg 65% 48.7%;
  --color-red12: hsl(354, 50%, 14.6%);
  --color-red12-hsl: 354deg 50% 14.6%;
  --color-green1: hsl(136, 50%, 98.9%);
  --color-green1-hsl: 136deg 50% 98.9%;
  --color-green2: hsl(138, 62.5%, 96.9%);
  --color-green2-hsl: 138deg 62.5% 96.9%;
  --color-green3: hsl(139, 55.2%, 94.5%);
  --color-green3-hsl: 139deg 55.2% 94.5%;
  --color-green4: hsl(140, 48.7%, 91%);
  --color-green4-hsl: 140deg 48.7% 91%;
  --color-green5: hsl(141, 43.7%, 86%);
  --color-green5-hsl: 141deg 43.7% 86%;
  --color-green6: hsl(143, 40.3%, 79%);
  --color-green6-hsl: 143deg 40.3% 79%;
  --color-green7: hsl(146, 38.5%, 69%);
  --color-green7-hsl: 146deg 38.5% 69%;
  --color-green8: hsl(151, 40.2%, 54.1%);
  --color-green8-hsl: 151deg 40.2% 54.1%;
  --color-green9: hsl(151, 55%, 41.5%);
  --color-green9-hsl: 151deg 55% 41.5%;
  --color-green10: hsl(152, 57.5%, 37.6%);
  --color-green10-hsl: 152deg 57.5% 37.6%;
  --color-green11: hsl(153, 67%, 28.5%);
  --color-green11-hsl: 153deg 67% 28.5%;
  --color-green12: hsl(155, 40%, 14%);
  --color-green12-hsl: 155deg 40% 14%;
  --color-blue1: hsl(206, 100%, 99.2%);
  --color-blue1-hsl: 206deg 100% 99.2%;
  --color-blue2: hsl(210, 100%, 98%);
  --color-blue2-hsl: 210deg 100% 98%;
  --color-blue3: hsl(209, 100%, 96.5%);
  --color-blue3-hsl: 209deg 100% 96.5%;
  --color-blue4: hsl(210, 98.8%, 94%);
  --color-blue4-hsl: 210deg 98.8% 94%;
  --color-blue5: hsl(209, 95%, 90.1%);
  --color-blue5-hsl: 209deg 95% 90.1%;
  --color-blue6: hsl(209, 81.2%, 84.5%);
  --color-blue6-hsl: 209deg 81.2% 84.5%;
  --color-blue7: hsl(208, 77.5%, 76.9%);
  --color-blue7-hsl: 208deg 77.5% 76.9%;
  --color-blue8: hsl(206, 81.9%, 65.3%);
  --color-blue8-hsl: 206deg 81.9% 65.3%;
  --color-blue9: hsl(206, 100%, 50%);
  --color-blue9-hsl: 206deg 100% 50%;
  --color-blue10: hsl(208, 100%, 47.3%);
  --color-blue10-hsl: 208deg 100% 47.3%;
  --color-blue11: hsl(211, 100%, 43.2%);
  --color-blue11-hsl: 211deg 100% 43.2%;
  --color-blue12: hsl(211, 100%, 15%);
  --color-blue12-hsl: 211deg 100% 15%;
  --color-pink1: hsl(322, 100%, 99.4%);
  --color-pink1-hsl: 322deg 100% 99.4%;
  --color-pink2: hsl(323, 100%, 98.4%);
  --color-pink2-hsl: 323deg 100% 98.4%;
  --color-pink3: hsl(323, 86.3%, 96.5%);
  --color-pink3-hsl: 323deg 86.3% 96.5%;
  --color-pink4: hsl(323, 78.7%, 94.2%);
  --color-pink4-hsl: 323deg 78.7% 94.2%;
  --color-pink5: hsl(323, 72.2%, 91.1%);
  --color-pink5-hsl: 323deg 72.2% 91.1%;
  --color-pink6: hsl(323, 66.3%, 86.6%);
  --color-pink6-hsl: 323deg 66.3% 86.6%;
  --color-pink7: hsl(323, 62%, 80.1%);
  --color-pink7-hsl: 323deg 62% 80.1%;
  --color-pink8: hsl(323, 60.3%, 72.4%);
  --color-pink8-hsl: 323deg 60.3% 72.4%;
  --color-pink9: hsl(322, 65%, 54.5%);
  --color-pink9-hsl: 322deg 65% 54.5%;
  --color-pink10: hsl(322, 63.9%, 50.7%);
  --color-pink10-hsl: 322deg 63.9% 50.7%;
  --color-pink11: hsl(322, 75%, 46%);
  --color-pink11-hsl: 322deg 75% 46%;
  --color-pink12: hsl(320, 70%, 13.5%);
  --color-pink12-hsl: 320deg 70% 13.5%;
  --color-orange1: hsl(24, 70%, 99%);
  --color-orange1-hsl: 24deg 70% 99%;
  --color-orange2: hsl(24, 83.3%, 97.6%);
  --color-orange2-hsl: 24deg 83.3% 97.6%;
  --color-orange3: hsl(24, 100%, 95.3%);
  --color-orange3-hsl: 24deg 100% 95.3%;
  --color-orange4: hsl(25, 100%, 92.2%);
  --color-orange4-hsl: 25deg 100% 92.2%;
  --color-orange5: hsl(25, 100%, 88.2%);
  --color-orange5-hsl: 25deg 100% 88.2%;
  --color-orange6: hsl(25, 100%, 82.8%);
  --color-orange6-hsl: 25deg 100% 82.8%;
  --color-orange7: hsl(24, 100%, 75.3%);
  --color-orange7-hsl: 24deg 100% 75.3%;
  --color-orange8: hsl(24, 94.5%, 64.3%);
  --color-orange8-hsl: 24deg 94.5% 64.3%;
  --color-orange9: hsl(24, 94%, 50%);
  --color-orange9-hsl: 24deg 94% 50%;
  --color-orange10: hsl(24, 100%, 46.5%);
  --color-orange10-hsl: 24deg 100% 46.5%;
  --color-orange11: hsl(24, 100%, 37%);
  --color-orange11-hsl: 24deg 100% 37%;
  --color-orange12: hsl(15, 60%, 17%);
  --color-orange12-hsl: 15deg 60% 17%;
  --color-yellow1: hsl(60, 54%, 98.5%);
  --color-yellow1-hsl: 60deg 54% 98.5%;
  --color-yellow2: hsl(52, 100%, 95.5%);
  --color-yellow2-hsl: 52deg 100% 95.5%;
  --color-yellow3: hsl(55, 100%, 90.9%);
  --color-yellow3-hsl: 55deg 100% 90.9%;
  --color-yellow4: hsl(54, 100%, 86.6%);
  --color-yellow4-hsl: 54deg 100% 86.6%;
  --color-yellow5: hsl(52, 97.9%, 82%);
  --color-yellow5-hsl: 52deg 97.9% 82%;
  --color-yellow6: hsl(50, 89.4%, 76.1%);
  --color-yellow6-hsl: 50deg 89.4% 76.1%;
  --color-yellow7: hsl(47, 80.4%, 68%);
  --color-yellow7-hsl: 47deg 80.4% 68%;
  --color-yellow8: hsl(48, 100%, 46.1%);
  --color-yellow8-hsl: 48deg 100% 46.1%;
  --color-yellow9: hsl(53, 92%, 50%);
  --color-yellow9-hsl: 53deg 92% 50%;
  --color-yellow10: hsl(50, 100%, 48.5%);
  --color-yellow10-hsl: 50deg 100% 48.5%;
  --color-yellow11: hsl(42, 100%, 29%);
  --color-yellow11-hsl: 42deg 100% 29%;
  --color-yellow12: hsl(40, 55%, 13.5%);
  --color-yellow12-hsl: 40deg 55% 13.5%;
  --color-contrast: hsl(0, 0%, 0%);
  --color-contrast-hsl: 0deg 0% 0%;
  --color-text-primary: hsl(0, 0%, 0%);
  --color-text-primary-hsl: 0deg 0% 0%;
  --color-text-secondary: hsl(206, 6%, 43%);
  --color-text-secondary-hsl: 206deg 6% 43%;
}
